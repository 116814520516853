import { useEffect, useRef } from "react";
import { CgSpinner } from "react-icons/cg";
import { Link } from "react-router-dom";
import styled from "styled-components";

export function Select(props: JSX.IntrinsicElements["select"]) {
  return (
    <select
      {...props}
      style={{ width: "100%", ...props.style }}
      className={[props.className, "btl-select"].join(" ")}
    />
  );
}

const ButtonStyle = styled.button`
  white-space: nowrap;

  &:not(:disabled) {
    cursor: pointer;
  }

  font-size: 0.9rem;
`;

const ButtonLinkStyle = styled(Link)`
  white-space: nowrap;
  cursor: pointer;
  text-decoration: none;
  font-size: 0.9rem;
`;

const ButtonExternlLinkStyle = styled.a`
  white-space: nowrap;
  cursor: pointer;
  text-decoration: none;
  font-size: 0.9rem;
`;

const Spinner = styled(CgSpinner)`
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;

type InputProps = JSX.IntrinsicElements["input"];

export function Input(props: InputProps) {
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const elm = ref.current;
    if (elm && props.autoFocus) {
      elm.focus();
    }
  }, [props.autoFocus]);

  return (
    <input
      {...props}
      className={[props.className, "btl-input"].join(" ")}
      ref={ref}
    />
  );
}

export interface ButtonProps
  extends Pick<
    JSX.IntrinsicElements["button"],
    | "children"
    | "style"
    | "className"
    | "onClick"
    | "type"
    | "tabIndex"
    | "disabled"
  > {
  loading?: boolean;
  href?: string;
  onClickCapture?: () => any;
}

export function Button({ loading, href, onClick, ...props }: ButtonProps) {
  const content = (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        whiteSpace: "inherit",
      }}
    >
      {props.children}
      {loading && (
        <Spinner style={{ minWidth: "1em", marginLeft: "0.1ch" }} size="1em" />
      )}
    </div>
  );

  return href && !props.disabled ? (
    linkIsInternal(href) ? (
      <ButtonLinkStyle
        {...props}
        to={href}
        className={[props.className, "btl-button"].join(" ")}
      >
        {content}
      </ButtonLinkStyle>
    ) : (
      <ButtonExternlLinkStyle
        {...props}
        href={href}
        onClickCapture={() => props.onClickCapture?.()}
        className={[props.className, "btl-button"].join(" ")}
      >
        {content}
      </ButtonExternlLinkStyle>
    )
  ) : (
    <ButtonStyle
      {...props}
      className={[props.className, "btl-button"].join(" ")}
      onClick={loading ? undefined : onClick}
    >
      {content}
    </ButtonStyle>
  );
}

function linkIsInternal(href?: string) {
  if (typeof href === "undefined") {
    return false;
  }

  if (/^mailto:/.test(href)) {
    return false;
  }

  return !/(http(s|):|)\/\//i.test(href);
}
